import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Box } from '@chakra-ui/react'

import FormContact from '../components/formContact'
import Layout from '../components/layout'
import SocialLinks from '../components/socialLinks'

import { Article, Section } from '../ui'

function ContactPage() {
  const { t } = useTranslation()

  const onSubmit = async (values, setSuccess) => {
    const body = values
    body.to = 'info@hotelmayansecret.com'

    const res = await fetch(`https://form-server.vercel.app/api`, {
      headers: {
        Accept: 'application/json',
      },
      method: 'post',
      body: JSON.stringify(body),
    })

    if (res.ok) setSuccess(true)
    else setSuccess(false)
  }

  return (
    <Layout title={t('contact:title')} variantHeader="solid">
      <Article display="flex" flexDirection={{ base: 'column', lg: 'row' }} minHeight="100vh">
        <Section flex="50%" title={t('contact:findUs')} spacing={6}>
          <SocialLinks />
          <Box
            as="iframe"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15132.718803922353!2d-88.3789479!3d18.5207798!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4ea31b61e2a59f9a!2sMayan%20Secret%20Hotel%20Boutique!5e0!3m2!1sen!2smx!4v1616532623211!5m2!1sen!2smx"
            width="100%"
            height="300px"
            frameBorder="0"
            style={{ border: '0' }}
            aria-hidden="false"
          />
        </Section>
        <Section
          flex="50%"
          title={t('contact:form.title')}
          subtitle={t('contact:form.subtitle')}
          backgroundColor="gray.100"
        >
          <Box
            boxShadow="0px 0px 10px rgba(0,0,0,0.1)"
            padding={4}
            borderRadius="15px"
            backgroundColor="white"
          >
            <FormContact
              hideOnSuccess
              onSubmit={(values, setSuccess) => onSubmit(values, setSuccess)}
              name={{
                label: t('contact:form.inputs.name.label'),
                required: t('contact:form.inputs.name.required'),
                invalid: t('contact:form.inputs.name.invalid'),
                placeholder: t('contact:form.inputs.name.placeholder'),
              }}
              email={{
                required: t('contact:form.inputs.email.required'),
                invalid: t('contact:form.inputs.email.invalid'),
              }}
              message={{
                label: t('contact:form.inputs.message.label'),
                required: t('contact:form.inputs.message.required'),
                invalid: t('contact:form.inputs.message.invalid'),
                placeholder: t('contact:form.inputs.message.placeholder'),
              }}
              successText={t('contact:form.success')}
              buttonText={t('contact:form.inputs.button')}
            />
          </Box>
        </Section>
      </Article>
    </Layout>
  )
}

export default ContactPage
